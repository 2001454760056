<template>
    <div class="gpt-translate-history-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0; padding: 0;">
            <div class="translate-history-toolbar">
                <div class="back-btn" @click="goBackEvent" ><!-- $router.go(-1) -->
                     <i class="el-icon-back"></i>
                 </div>
                 <span style=" font-weight: bold">{{$t('tools.tools_gptTranslateHistory_page_tips_1')}}</span>
                
            </div>
        </div>
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0;">
            <div class="left">
                <el-button type="primary" size="small" round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">{{$t('tools.tools_gptTranslateHistory_page_btn_1')}}</el-button>
                <el-button size="small" round style="margin-left:10px;" @click="$router.replace('/account/agentToken')">{{$t('tools.tools_gptTranslateHistory_page_btn_2')}}</el-button>
            </div>
            <div class="right">
                <div style="width:200px;">
                    <el-input :placeholder="$t('tools.tools_gptTranslateHistory_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <template>
                    <div class="margin-l-10" v-permission="`project:download`">
                        <!-- <el-tooltip content="下载项目" placement="top">
                            <el-button plain round size="small" @click="batchDownloadBtnEvent" :loading="batchDownloadLoading" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-download-1" v-if="!batchDownloadLoading"></svg-icon>
                            </el-button>
                        </el-tooltip> -->
                        <el-dropdown @command="batchDownloadBtnEvent">
                            <el-button plain round size="small" :loading="batchDownloadLoading" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-download-1" v-if="!batchDownloadLoading"></svg-icon>
                            </el-button>
                          <el-dropdown-menu>
                            <el-dropdown-item command="target">{{$t('tools.tools_gptTranslateHistory_page_tips_2')}}</el-dropdown-item>
                            <el-dropdown-item command="bilingual">{{$t('tools.tools_gptTranslateHistory_page_tips_3')}}</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        
                    </div>
                    <div class="margin-l-10" v-permission="`project:detailRouter:delete`">
                        <el-tooltip :content="$t('tools.tools_gptTranslateHistory_page_tips_4')" placement="top">
                            <el-button plain round size="small" @click="batchDeleteBtnEvent()" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-trash-o-1"></svg-icon>
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
            </div>
        </div>
        <div class="project-table-list">
            <el-table
                :data="list"
                ref="tableList"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                row-key="taskId"
                lazy
                :load="load"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :expand-row-keys="expandRowKeys"
                @expand-change="handleExpandChange"
                >
            <el-table-column type="selection" fixed :selectable="handleSetSelectionEnable" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_1')" width="300" fixed :show-overflow-tooltip="true" prop="name" column-key="FILENAME">
                <template slot-scope="scope">
                    <div class="file-icon">
                        <!-- <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.iconType] ? config.FILE_TYPE_MATCH['.' + scope.row.iconType].icon : ''}`"></svg-icon> -->
                        <svg-icon :name="getIconName(scope.row)"></svg-icon>
                    </div>
                    <span class="text-omission">{{scope.row.name}}</span>   
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_2')" prop="fileCount" align="center"></el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_3')" min-width="110" align="center">
                <template slot-scope="scope">
                    <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_4')" align="center" prop="charCount">
                <template slot-scope="scope">
                    <span>{{scope.row.wordCount || '-'}}</span> <!-- / {{scope.row.charCount || '-'}} -->
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_5')" header-align="center" align="center" width="280">
                <template slot-scope="scope">
                    <template v-if="!scope.row.subTaskId">
                        {{ scope.row.statusDesc }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_6')" header-align="center" align="center" min-width="280">
                <template slot-scope="scope">
                    <template v-if="!scope.row.hasOwnProperty('subTaskId')">
                        <div class="flex flex-justify-center ">
                            <div class="task-progress" style="margin-top: 4px;max-width: 150px;">
                                <el-progress :percentage="scope.row.process*1" :stroke-width="20" color="#D3F4EC" :show-text="false"></el-progress>
                                <div class="percentage">{{scope.row.process}}%</div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="scope.row.hasOwnProperty('subTaskId') && scope.row.status === 'S'">
                        <div class="flex flex-justify-center ">
                            <div class="fonts-14">
                                <svg-icon name="ib-complete" className="color-1AC49C margin-r-5"></svg-icon>
                                {{ scope.row.statusDesc }}</div>
                        </div>
                    </template>
                    <template v-else-if="scope.row.hasOwnProperty('subTaskId') && (scope.row.status === 'P' || scope.row.status === 'T'|| scope.row.status === 'Q')">
                        <div class="flex flex-justify-center ">
                            <div class="fonts-14"><i class="el-icon-loading color-blue margin-r-5"></i> {{ scope.row.statusDesc }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="flex flex-justify-center ">
                        <div class="fonts-14"> <svg-icon name="ib-error-o" className="color-DE0000 margin-r-5"></svg-icon> {{ scope.row.statusDesc }}</div>
                         </div>
                    </template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_7')" header-align="center" align="center" min-width="140">
                <template slot-scope="scope">
                    <svg-icon name="ib-upload-file" className="fonts-20 color-999 margin-r-10"></svg-icon>{{getTimeText(scope.row.createTime)}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('tools.tools_gptTranslateHistory_page_table_column_8')" fixed="right" width="70" >
                <template slot-scope="scope">
                     <template v-if="!scope.row.hasOwnProperty('subTaskId') && (scope.row.status == 'S' || scope.row.status === 'F'|| scope.row.status === 'TP')">
                        <div class="flex flex-align-center">
                            <div class="click-btn margin-r-10" v-if="scope.row.status == 'S'">
                                <el-dropdown placement="bottom" @command="command => downloadResult(scope.row, command)">
                                <svg-icon name="ib-download-1" class="color-3f3f3f fonts-18"></svg-icon>
                                <el-dropdown-menu slot="dropdown">
                                    <template>
                                        <el-dropdown-item command="target">{{$t('tools.tools_gptTranslateHistory_page_tips_5')}}</el-dropdown-item>
                                        <el-dropdown-item command="bilingual">{{$t('tools.tools_gptTranslateHistory_page_tips_6')}}</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <template v-else-if="scope.row.status == 'TP'">
                                <el-tooltip :content="$t('tools.tools_gptTranslateHistory_page_tips_7')" placement="top">
                                    <div class="click-btn margin-r-10" @click="handleFileReset(scope.row.taskId)">
                                        <svg-icon name="ib-refresh" class="color-3f3f3f fonts-18"></svg-icon>
                                    </div>
                                </el-tooltip>
                            </template>
                            <el-tooltip :content="$t('tools.tools_gptTranslateHistory_page_tips_8')" placement="top">
                                <div @click.stop="batchDeleteBtnEvent(scope.row.taskId)" class="click-btn">
                                    <svg-icon name="ib-trash-o" class="color-3f3f3f fonts-18"></svg-icon>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                     <template v-else>
                        
                    </template>
                </template>
            </el-table-column>
            </el-table>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                    background 
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next"
                    :page-size="pageSize"
                    :total="total">
            </el-pagination>
        </div>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteProject"
            @cancelEvent="deleteCancelEvent"></alert-dialog>
    </div>
</template>
<script>
import moment from "moment";
import config from '@/utils/config';
import FullLoading from '@/components/FullLoading';
import AlertDialog from '@/components/AlertDialog';
import { toolsApi, docApi, commonApi } from '@/utils/api';
import LanguageDirection from '@/components/LanguageDirection';
import tools from '@/utils/tools';
export default {
    components:{
        AlertDialog, FullLoading,
        LanguageDirection
    },
    data () {
        return {
            alertInfo:{
                message: this.$t('tools.tools_gptTranslateHistory_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_gptTranslateHistory_page_data_alertInfo_cancelButtonName'),
                buttonType:'danger',
            },
            searchForm:{
                kw:'',
                sort:'1'
            },
            list: [],
            expandRowKeys: [],
            total: 0,
            pageNumber: 1,
            pageSize: 6,
            selectedItem: [],
            batchDownloadLoading: false,
            refreshLoading:false,
            config
        }
    },

    methods: {
        initPage(type){
            let self = this;
            let url = `${toolsApi.queryGPTTranslateTasks}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            if(this.searchForm.kw){
                url = url + `&keyword=${encodeURI(this.searchForm.kw)}`;
            }
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.list = res.data.records.map(item => ({
                        ...item,
                        hasChildren: true  // 直接设置所有父节点都有子节点
                    }));
                    this.total = res.data.total;
                    this.$nextTick(()=>{
                        if(!this.searchForm.kw){
                            document.querySelector('.el-table__expand-icon').click();
                        }
                    })
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        getTimeText(t) {
            let tYear = moment(t).year();
            let cYear = moment().year();
            if(tYear === cYear) {
                return moment(t).format(`MM-DD HH:mm`);
            }else {
                return moment(t).format(`YYYY-MM-DD`);
            }
        },
        handleFileReset(taskId) {
            let url = `${toolsApi.startGPTTranslateTask}?taskId=${taskId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    if(res.data.errorCode === 'TOKENS_LACK') {
                        this.$message.error(this.$t('tools.tools_gptTranslateHistory_page_method_handleFileReset_message_1'));
                    }
                    this.list = [];
                    this.initPage();
                }
            }).finally(()=>{

            })
        },
        handleExpandChange(row, expanded) {
            // 更新展开状态
            // 检查是否需要添加或移除行 ID
            if (expanded) {
            // 如果展开，添加到 expandRowKeys
                if (!this.expandRowKeys.includes(row.taskId)) {
                    this.expandRowKeys.push(row.taskId);
                }
            } else {
                // 如果折叠，移除 expandRowKeys 中的 ID
                this.expandRowKeys = this.expandRowKeys.filter(id => id !== row.taskId);
            }
        },
        // 检查给定的任务 ID 是否在展开状态中
        isExpanded(taskId) {
            //console.log(this.expandRowKeys)
            return this.expandRowKeys.includes(taskId);
        },
        getIconName(row) {
            if (row.hasChildren) {
                // 一级菜单（具有子项）
                return this.isExpanded(row.taskId) ? 'ib-project-open' : 'ib-project-o';
            } else {
                // 二级菜单（没有子项）
                return `ic-${this.config.FILE_TYPE_MATCH['.' + row.iconType] ? this.config.FILE_TYPE_MATCH['.' + row.iconType].icon : ''}`;
            }
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        handleSetSelectionEnable(row, index) {
            return (row.status === 'S' || row.status === 'F') && !row.hasOwnProperty('subTaskId');
        },
        goBackEvent(){
            if(this._events.backEvent){
                this.$emit('backEvent');
            }else{
                this.$router.go(-1);
            }
        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
         handleSelectionChange(selection) {
            this.selectedItem = selection;
        },
         batchDownloadBtnEvent(type){
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('tools.tools_gptTranslateHistory_page_method_batchDownloadBtnEvent_message_1'));
                return;
            }
            let ids = [];
            this.selectedItem.map(item => {
            ids.push(item.taskId);
            });
            if(ids.length > 0){
                this.batchDownloadLoading = true;
                let url = commonApi.batchCreateDownloadTask;
                let postData = {
                    title: ids.length === 1 ? this.selectedItem[0].name : this.$t('tools.tools_gptTranslateHistory_page_method_batchDownloadBtnEvent_message_2', {size: ids.length}),
                    metadataIdList: ids,
                    metadataType: "TOOLKIT_GT",
                    moreOptions: {
                        exportFileType: type,
                    }
                };
                this.$ajax.post(url, postData).then(res=>{
                    if(res.status === 200){
                        this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                        this.$refs.tableList.clearSelection();
                    }
                }).finally(()=>{
                    this.batchDownloadLoading = false;
                })
            }
        },
        downloadResult(params, type){
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: params.name,
                metadataIdList: [params.taskId],
                metadataType: "TOOLKIT_GT",
                moreOptions: {
                    exportFileType: type,
                }
            };
            this.$ajax.post(url, postData).then(res=>{
                // this.$message.success('导出任务已创建，导出成功后即可下载。');
                this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
            }).finally(()=>{
                
            })
        },
        deleteCancelEvent(){
            this.selectedItem = [];
            this.$refs.tableList.clearSelection();
        },
        batchDeleteBtnEvent(taskId){
            // console.log(taskId)
            if(!taskId && this.selectedItem.length === 0) {
                this.$message.error(this.$t('tools.tools_gptTranslateHistory_page_method_batchDeleteBtnEvent_message_1'));
                return;
            }
            let ids = [];
            if(taskId) {
                ids.push(taskId);
            }
            else{
                this.selectedItem.map(item => {
                ids.push(item.taskId);
            });
            }
            this.selectedProjectIds = ids;
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteProject(){
            let url = `${toolsApi.deleteGPTTranslateTask}?ids=${this.selectedProjectIds}`;
            this.$ajax.delete(url).then(res => {
                if(res.status === 200){
                    this.initPage();
                    this.$message.success(this.$t('tools.tools_gptTranslateHistory_page_method_deleteProject_message_1'));
                }
            });
        },
        load(tree, treeNode, resolve) {
            let url = `${toolsApi.queryGPTTranslateTasksDetail}?taskId=${tree.taskId}&pageNumber=${this.pageNumber}`;
            //this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    const children = res.data.records.map(item => ({
                    ...item,
                    taskId: item.subTaskId,
                    hasChildren: false 
                }));
                // const children = [{
                //     "taskId": "18062363869859880",
                //     "subTaskId": "18062363869859880",
                //     "name": "英语异常.pdf",
                //     "sourceLang": "en",
                //     "targetLang": "zh",
                //     "engine": "ChatGPT",
                //     "status": "S",
                //     "statusDesc": "成功",
                //     "createTime": "2024-06-27 16:01:27",
                //     "iconType": "pdf",
                //     "charCount": 0,
                //     "wordCount": 100,
                //     "fileCount": 0,
                //     "logs": null,
                //     "process": 100,
                //     "current": null,
                //     "hasChildren": false,
                //     }]
                    resolve(children);
                }
                else{
                    resolve([]);
                }
            }).finally(()=>{
                //this.refreshLoading = false;
            })
      },
      downloadFile(taskId){

      },
      deleteFile(taskId){

      },
    },
    mounted() {
        this.initPage('init');
    },
}
</script>
<style lang="scss" scoped>
.gpt-translate-history-page {
    height: 100%;
    .translate-history-toolbar{
        display: flex;
        align-items: center;
        padding: 10px 0px;
        > .back-btn{
            width: 30px;
            height: 30px;
            font-size: 20px;
            line-height: 30px;
            cursor: pointer;
            text-align: center;
            &:hover,&:focus{
                background-color: #0000001A;
                border-radius: 4px;
            }
            > i{
                font-weight: 600;
            }
         }
    }
}
.project-table-list{
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    .click-btn {
        cursor: pointer;
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
    .file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
}
</style>
